import { getCurrentLanguage } from './config';
const actions = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  ACTIVATE_LANG_MODAL: 'ACTIVATE_LANG_MODAL',
  switchActivation: () => ({
    type: actions.ACTIVATE_LANG_MODAL,
  }),
  changeLanguage: (language) => {
    //Bltagy Language
    localStorage.setItem('lang', language);
    if (language === 'english') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
    return {
      type: actions.CHANGE_LANGUAGE,
      language: getCurrentLanguage(language),
    };
  },
};
export default actions;
